<template>
  <div v-if="user" class="mt-5">
    <List :requests="confRequests" :type="type" />
  </div>
</template>

<script>
import { orderBy } from 'lodash'
import { mapState, mapActions } from 'vuex'

import List from './components/List'

export default {
  data() {
    return {
      type: 'open'
    }
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      user: state => state.user.user,
      status: state => state.request.status,
      requests: state => state.request.requests,
      pendingRequests: state => state.request.pendingRequests,
      completedRequests: state => state.request.completedRequests,
    }),

    confRequests: function () {
      let requests = []
      
      if (this.$route.name === 'CompletedRequests') {
        requests = this.completedRequests
      }
      else if (this.$route.name === 'PendingReviewRequests') {
        requests = this.pendingRequests
        requests = requests.filter(request => request.status === 'pending_review')
      }
      else {
        let excludedStatus = (this.user && this.user.role === 'client') ? ['completed'] : ['completed', 'pending_review']
        requests = this.requests.filter(request => !excludedStatus.includes(request.status))
      }

      requests = orderBy(requests, 'requestNumber', 'desc')

      if (this.user && this.user.role === 'member') {
        requests = orderBy(requests, 'status', 'escalated')
      }
      else if (this.user && this.user.role === 'client') {
        requests = orderBy(requests, (request) => request.status === 'pending_review', 'desc')
      }

      return requests
    }
  },

  /*------------------------------------------------------------------------------
   * WATCH
   *----------------------------------------------------------------------------*/
  watch: {
    'user': {
      handler(user) {
        if (user && user.role === 'client') {
          Promise.resolve(this.$store.dispatch('brand_profile/getMyCompanies'))
          .then(() => {
            setTimeout(() => {
              this.fetchRequests()  
            }, 1000)
          })
        }
        else if (user) {
          this.fetchRequests()
        }
      },
      immediate: true
    },
    
    '$route': {
      handler() {
        if (this.user) this.fetchRequests()

        if (this.$route.name == 'CompletedRequests') {
          this.type = 'completed'
        }
        else if (this.$route.name == 'PendingReviewRequests') {
          this.type = 'pending'
        }
        else {
          this.type = 'open'
        }
      },
    },
  },

  /*------------------------------------------------------------------------------
   * COMPONENTS
   *----------------------------------------------------------------------------*/
  components: {
    List
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    ...mapActions('request', [
      'getRequests',
      'getRequestsAsMember',
    ]),

    fetchRequests() {
      let status = 'open'
      
      if (!this.status.firstLoad && this.$route.name === 'OpenRequests') {
        status = 'open'
        this.getRequests(status)
        
        if (this.user && this.user.role === 'client') {
          this.getRequestsAsMember(status)
        }
      }
      else if (!this.status.completedFirstLoad && this.$route.name === 'CompletedRequests') {
        status = 'completed'
        this.getRequests(status)
        
        if (this.user && this.user.role === 'client') {
          this.getRequestsAsMember(status)
        }
      }
      else if (!this.status.pendingFirstLoad && this.$route.name === 'PendingReviewRequests') {
        status = 'pending'
        this.getRequests(status)
        
        if (this.user && this.user.role === 'client') {
          this.getRequestsAsMember(status)
        }
      }


    },
  },
}
</script>