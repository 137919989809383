var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.user && _vm.user.organization)?_c('Search',{attrs:{"requests":_vm.requests},on:{"selected":_vm.startSearch}}):_vm._e(),(_vm.status.getting && !_vm.requests.length)?_c('preloader',{attrs:{"message":"Please wait..."}}):_vm._e(),(!_vm.status.getting && !_vm.requests.length)?_c('v-alert',{attrs:{"type":"info","border":"left","text":""}},[_vm._v(" No request found. ")]):_vm._e(),(_vm.requests.length)?_c('div',[_c('v-row',{staticClass:"d-none d-md-flex mb-2 text-uppercase font-weight-bold grey--text caption",attrs:{"no-gutters":"","align":"center"}},[_c('v-col',{staticStyle:{"max-width":"80px !important"},attrs:{"cols":"1"}},[_vm._v("   ")]),_c('v-col',{staticClass:"pl-5",attrs:{"cols":"3"}},[_vm._v(" Client ")]),_c('v-col',{staticClass:"pl-5",attrs:{"cols":"2"}},[_vm._v(" Type ")]),_c('v-col',{staticClass:"pl-5",attrs:{"cols":"2"}},[_vm._v(" Deadline ")]),_c('v-col',{staticClass:"pl-5",attrs:{"cols":"2"}},[_vm._v(" Assignee ")]),_c('v-col',{staticClass:"pl-5",attrs:{"cols":"1"}},[_vm._v(" Status ")])],1),_vm._l((_vm.orderBy(_vm.requests, 'orderNumber', 'asc')),function(request){return _c('v-hover',{key:request.id,scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"d-flex rounded-lg mb-2 cursor-default transition",class:{ 'box-shadow': hover },attrs:{"to":{ name: 'Request', params: { id: request.id } },"outlined":""}},[_c('v-row',{attrs:{"no-gutters":"","align":"center"}},[_c('v-col',{staticClass:"d-flex align-center justify-center request_priority",class:[
              _vm.user.role == 'client'
                 ? ("" + (request.status == 'completed'
                      ? 'request_priority__medium'
                      : 'request_priority__none'))
                : ("request_priority__" + (request.priority || 'none')),
              { 'fill-height': _vm.$vuetify.breakpoint.mdAndUp } ],attrs:{"cols":"12","md":"1"}},[_c('v-card-text',{staticClass:"text-center py-0"},[_c('v-avatar',{attrs:{"size":"24","tile":""}},[(
                    [
                      'in_progress',
                      'checking_quality',
                      'reviewing_with_client',
                      'paused',
                      'pending_review' ].includes(request.status)
                  )?_c('img',{attrs:{"src":require('@/assets/badge-icon-open.svg'),"alt":"open"}}):_vm._e(),(request.status == 'completed')?_c('img',{attrs:{"src":require('@/assets/badge-icon-completed.svg'),"alt":"delivered"}}):_vm._e(),(request.status == 'escalated')?[(
                      request.status == 'escalated' &&
                      _vm.user &&
                      _vm.user.role !== 'client'
                    )?_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-alert-circle-outline")]):_c('img',{attrs:{"src":require('@/assets/badge-icon-open.svg'),"alt":"open"}})]:_vm._e(),(request.status == 'closed')?[(
                      request.status == 'closed' &&
                      _vm.user &&
                      _vm.user.role !== 'client'
                    )?_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-close-circle-outline")]):_c('img',{attrs:{"src":require('@/assets/badge-icon-open.svg'),"alt":"open"}})]:_vm._e()],2)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-card-text',{staticClass:"py-0"},[_c('v-card-text',{staticClass:"d-flex align-center px-0"},[_c('v-badge',{attrs:{"value":_vm.haveMessage(request),"color":"transparent","size":"15","overlap":"","bottom":"","left":""},scopedSlots:_vm._u([{key:"badge",fn:function(){return [_c('span',{staticClass:"wave",style:({ fontSize: '20px' })},[_vm._v("👋")])]},proxy:true}],null,true)},[_c('user-photo',{staticClass:"mr-3 align-self-start",attrs:{"id":request.user,"photoSize":"thumb","size":30,"rounded":""}})],1),_c('div',[_c('div',{staticClass:"caption"},[_c('span',{staticClass:"blue-grey--text"},[_vm._v(_vm._s(_vm.$store.getters["users/getFullName"](request.user)))]),_c('span',{staticClass:"grey--text ml-1"},[_vm._v("("+_vm._s(_vm._f("fromNowComplete")(request.created))+")")]),(request.company_profile)?_c('div',{staticClass:"caption grey--text"},[_vm._v(" "+_vm._s(_vm.$store.getters["brand_profile/profile"]( request.company_profile ).name)+" ")]):_vm._e()])])],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-card-text',[_c('div',{staticClass:"caption blue-grey--text"},[_vm._v(" "+_vm._s(_vm.$store.getters["types/getProductName"](request.requestType))+" ")]),_c('div',{staticClass:"info--text caption",style:({ lineHeight: '1 !important' })},[_vm._v(" #"+_vm._s(request.requestNumber)+" ")])])],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-card-text',{staticClass:"caption"},[_c('span',{staticClass:"blue-grey--text"},[_vm._v(" "+_vm._s(request.dueDate ? ("" + (_vm.$options.filters.formatDate(request.dueDate))) : "TBA")+" ")]),(
                  request.dueDate &&
                  _vm.moment(request.dueDate.toDate()).diff(_vm.moment(), 'hours') >
                    -24 &&
                  _vm.moment(request.dueDate.toDate()).diff(_vm.moment(), 'hours') < 0
                )?_c('span',{staticClass:"info--text"},[_vm._v(" today ")]):(request.dueDate)?_c('span',{staticClass:"ml-1",class:_vm.$overdue(request) ? 'error--text' : 'grey--text lighten-2'},[_vm._v(" "+_vm._s(_vm.moment(request.dueDate.toDate()).fromNow())+" ")]):_vm._e()])],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-card-text',{staticClass:"d-flex align-center"},[_c('Assignee',{attrs:{"request":request,"small":""}})],1)],1),_c('v-col',{attrs:{"cols":"12","md":"1"}},[_c('v-card-text',[_c('div',{staticClass:"d-flex align-center full-width"},[_c('StatusBadge',{attrs:{"request":request}})],1)])],1),_c('v-col',{staticClass:"text-right pr-3"},[_c('v-menu',{attrs:{"offset-y":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){$event.preventDefault();}}},on),[_c('v-icon',{attrs:{"color":"grey","small":""}},[_vm._v("mdi-dots-horizontal")])],1)]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{attrs:{"to":{ name: 'Request', params: { id: request.id } }}},[_c('v-list-item-title',[_vm._v("View")])],1),(_vm.$store.getters['request/canManage'])?[_c('v-divider'),_c('v-list-item',[_c('v-list-item-title',{staticClass:"grey--text caption"},[_vm._v("Update Priority")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.updateRequestPriority(request, 'high')}}},[_c('v-list-item-title',[_vm._v("High")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.updateRequestPriority(request, 'medium')}}},[_c('v-list-item-title',[_vm._v("Medium")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.updateRequestPriority(request, 'low')}}},[_c('v-list-item-title',[_vm._v("Low")])],1),_c('v-divider'),_c('v-list-item',{on:{"click":function($event){return _vm.attemptDelete(request)}}},[_c('v-list-item-title',{staticClass:"error--text"},[_vm._v("Delete")])],1)]:_vm._e()],2)],1)],1)],1)],1)]}}],null,true)})}),_c('div',{staticClass:"text-center mt-5"},[_c('v-btn',{attrs:{"loading":_vm.$store.state.request.status.getting,"color":"primary","small":""},on:{"click":function($event){return _vm.$store.dispatch('request/getRequests', _vm.type)}}},[_vm._v("Load More")])],1),_c('confirm-delete',{attrs:{"message":("Delete request #" + (_vm.toDelete.requestNumber) + "?"),"deleting":_vm.$store.state.request.status.deleting,"textConfirmation":"Request","show":_vm.deleteDialog,"captcha":""},on:{"confirmed":function($event){return _vm.deleteConfirmed()},"cancel":function($event){return _vm.closeDeleteDialog()}}})],2):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }