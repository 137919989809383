<template>
  <div class="d-flex justify-space-between align-center mb-5">
    <div class="caption grey--text">
      Showing <span class="font-weight-bold">{{ requests.length }}</span>
      {{ pluralize("request", requests.length) }}
    </div>

    <div class="d-flex flex-grow-1 justify-end gap-12 align-center flex-wrap">
      <v-btn
        @click="resetValues('clear')"
        v-if="search.search"
        color="default"
        small
      >
        <v-icon small left>mdi-close</v-icon>
        Clear Filters
      </v-btn>

      <!-- EMPLOYEE VIEW -->
      <v-sheet
        v-if="
          $store.state.user.user.organization &&
          !$store.getters['request/canManage'] &&
          !Object.keys(status.search).length
        "
        max-width="230"
        width="100%"
      >
        <v-select
          v-model="status.employeeView"
          class="dashboard-select field-shadow"
          :items="['assignee', 'follower']"
          prefix="Show requests as"
          @change="changeView()"
          item-value="value"
          hide-details
          outlined
          dense
        ></v-select>
      </v-sheet>

      <!-- SEARCH TYPE -->
      <v-sheet width="100%" max-width="250">
        <v-select
          class="dashboard-select field-shadow"
          v-model="search.search"
          @change="resetValues"
          prefix="Search by: "
          item-value="value"
          :items="searchBy"
          item-text="text"
          hide-details
          outlined
          dense
        ></v-select>
      </v-sheet>

      <!-- SELECT ASSIGNEE -->
      <v-sheet
        v-if="['assignee', 'follower'].includes(search.search)"
        width="100%"
        max-width="350"
      >
        <v-autocomplete
          :items="orderBy(employees, 'fullName', 'asc')"
          class="dashboard-select field-shadow"
          :loading="gettingEmployees"
          placeholder="Select user"
          item-text="fullName"
          item-value="userid"
          v-model="search.assignee"
          @input="updated"
          hide-details
          outlined
          dense
        >
          <template #selection="data">
            <v-chip
              @click:close="search.assignee = null"
              :input-value="data.selected"
              @click="data.select"
              v-bind="data.attrs"
              class="pl-1"
              close
              small
            >
              <user-photo
                :id="data.item.userid"
                photoSize="thumb"
                :size="22"
                class="mr-2"
              />

              {{ data.item.fullName }}
            </v-chip>
          </template>
          <template #item="data">
            <v-list-item-avatar
              :style="{ overflow: 'visible' }"
              class="mr-1"
              rounded
            >
              <user-photo
                :id="data.item.userid"
                photoSize="thumb"
                :size="30"
                rounded
              />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                {{ data.item.fullName }}
              </v-list-item-title>
              <v-list-item-subtitle class="caption">
                {{ data.item.employeeRole }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </template>
        </v-autocomplete>
      </v-sheet>

      <!-- SEARCH BY REQUEST NUMBER -->
      <v-sheet v-if="search.search == 'requestNumber'" max-width="250">
        <v-text-field
          @blur="updated(parseInt($event.target.value))"
          @keydown.enter="updated(parseInt($event.target.value))"
          placeholder="Type request number"
          class="field-shadow"
          hide-details
          outlined
          dense
        />
      </v-sheet>

      <!-- SEARCH BY DUE DATE -->
      <v-sheet v-if="search.search == 'due_date'" max-width="250">
        <v-menu offset-y>
          <template #activator="{ on }">
            <v-text-field
              :value="date ? $options.filters.formatDate(moment(date)) : ''"
              placeholder="Select a due date"
              class="field-shadow"
              hide-details
              v-on="on"
              outlined
              readonly
              dense
            />
          </template>
          <v-date-picker v-model="date" @change="updated"></v-date-picker>
        </v-menu>
      </v-sheet>

      <!-- SELECT REQUEST TYPES -->
      <v-sheet
        v-if="search.search == 'requestType'"
        width="100%"
        max-width="350"
      >
        <v-autocomplete
          :items="orderBy(requestTypes, 'name', 'asc')"
          class="dashboard-select field-shadow"
          placeholder="Select request type"
          :loading="gettingForms"
          v-model="search.orderType"
          item-text="name"
          @input="updated"
          item-value="id"
          hide-details
          outlined
          dense
        ></v-autocomplete>
      </v-sheet>

      <!-- STATUS -->
      <v-sheet v-if="search.search == 'status'" width="100%" max-width="250">
        <v-autocomplete
          :item-text="(item) => `${startCase(item.value)}`"
          class="dashboard-select field-shadow"
          placeholder="Select request type"
          :loading="gettingForms"
          item-value="value"
          :items="statuses"
          @input="updated"
          hide-details
          outlined
          dense
        ></v-autocomplete>
      </v-sheet>

      <!-- CLIENT -->
      <template v-if="search.search == 'user'">
        <v-btn
          @click="showUsersDialog()"
          v-if="!search.client"
          class="primary--text"
          depressed
          small
          >Search client</v-btn
        >

        <v-chip @click="showUsersDialog()" v-if="search.client" class="caption">
          <user-photo
            photoSize="thumb"
            :id="search.client"
            class="mr-2"
            :size="22"
          />

          {{ $store.getters["users/user"](client).fullName }} ({{
            $store.getters["users/user"](client).email
          }})
        </v-chip>
      </template>

      <!-- SEARCH BY COMPANY PROFILE -->
      <v-sheet v-if="search.search === 'company_profile'">
        <CompanyProfileSearch @updated="updated" />
      </v-sheet>

      <!-- SORT BY -->
      <v-sheet width="100%" max-width="250">
        <v-select
          :item-text="(item) => `${startCase(item.value)}`"
          class="dashboard-select field-shadow"
          v-model="status.sortBy"
          @change="changeView(false)"
          prefix="Sort by: "
          item-value="value"
          :items="sortBy"
          hide-details
          outlined
          dense
        ></v-select>
      </v-sheet>
    </div>

    <!-- EMPLOYEE DIALOG -->
    <find-user-dialog ref="userDialog" @selected="clientSelected" />
  </div>
</template>

<script>
import moment from "moment";
import { mapState } from "vuex";
import pluralize from "pluralize";
import { startCase, orderBy } from "lodash";

import CompanyProfileSearch from "./CompanyProfileSearch";
import FindUserDialog from "../../users/components/FindUserDialog";

export default {
  /*------------------------------------------------------------------------------
   * PROPS
   *-----------------------------------------------------------------------------*/
  props: {
    requests: Array,
  },

  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      moment,
      orderBy,
      pluralize,
      startCase,
      gettingForms: false,
      gettingEmployees: false,
      searchBy: [
        { text: "Assignee", value: "assignee" },
        { text: "Request Number", value: "requestNumber" },
        { text: "Client", value: "user" },
        { text: "Follower", value: "follower" },
        { text: "Request Type", value: "requestType" },
        { text: "Status", value: "status" },
        { text: "Due Date", value: "due_date" },
        { text: "Company Profile", value: "company_profile" },
      ],
      statuses: [
        { value: "in_progress" },
        { value: "checking_quality" },
        { value: "reviewing_with_client" },
        { value: "paused" },
        { value: "escalated" },
        { value: "closed" },
      ],
      sortBy: [{ value: "requestNumber" }, { value: "dueDate" }],
    };
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      status: (state) => state.request.status,
      search: (state) => state.request.search,
      employees: (state) => state.users.employees,
      requestTypes: (state) => state.types.products,
    }),
  },

  /*------------------------------------------------------------------------------
   * WATCH
   *----------------------------------------------------------------------------*/
  watch: {
    search: function () {
      console.log("updated");
      this.$store.commit("request/updateStatus", { firstLoad: false });
      this.$store.commit("request/updateStatus", { completedFirstLoad: false });
      this.$store.commit("request/updateStatus", {
        firstLoadPendingReviews: false,
      });
    },
  },

  /*------------------------------------------------------------------------------
   * COMPONENTS
   *----------------------------------------------------------------------------*/
  components: {
    FindUserDialog,
    CompanyProfileSearch,
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    updated(value) {
      this.$emit("selected", {
        type: this.search.search,
        value,
      });
    },

    resetValues(value) {
      this.$store.commit("request/resetSearchState");
      this.$store.commit("request/updateStatus", { firstLoad: false });
      this.$store.commit("request/updateStatus", { completedFirstLoad: false });
      this.$store.commit("request/updateStatus", { pendingFirstLoad: false });
      this.$store.commit("request/updateStatus", { lastVisible: null });
      this.$store.commit("request/updateStatus", {
        lastCompletedVisible: null,
      });

      if (value == "clear") {
        this.$store.commit("request/setSearch", null);
        this.$emit("selected", {});
      }
    },

    showUsersDialog() {
      this.$refs.userDialog.dialog = true;
    },

    clientSelected(value) {
      this.client = value;
      this.updated(value);
      this.$store.dispatch("users/getUser", value);
    },

    changeView(resetSearch = true) {
      this.$store.commit("request/clearRequests");
      this.$store.commit("request/updateStatus", { firstLoad: false });
      this.$store.commit("request/updateStatus", { completedFirstLoad: false });
      this.$store.commit("request/updateStatus", { pendingFirstLoad: false });
      this.$store.commit("request/updateStatus", { lastVisible: null });
      this.$store.commit("request/updateStatus", {
        lastCompletedVisible: null,
      });
      this.$store.dispatch(
        "request/getRequests",
        this.$route.name == "CompletedRequests"
      );
      if (resetSearch) {
        this.resetValues("clear");
        this.search = "clear";
      }
    },
  },

  /*------------------------------------------------------------------------------
   * MOUNTED
   *----------------------------------------------------------------------------*/
  mounted() {
    if (!this.$store.state.users.status.firstLoadEmployees)
      this.$store.dispatch("users/getEmployees");

    if (!this.$store.state.types.status.firstLoadAllProducts)
      this.$store.dispatch("types/getAllProducts");
  },
};
</script>